import React from 'react';
// import { useRef } from 'react';
import './styles/landing.scss';

const Renovation = () => {

    // const custom_build = useRef(null);
    const images = require.context('../images/portfolio', true);
    let image = images('./' + 8 + '.jpg');

    return (
        <main>
            <section className='body'>
                <section className='landing'>
                    <section className='project-details' id='renovations'>
                        <section className='visuals'>
                            <img src={image} alt='Image 10' className='static-visual'></img>
                        </section>
                        <section className='welcome-desc'>
                            <div className='landing-dec'>
                                <div className='title-desc'>            
                                    Transform your home as you wish
                                </div>
                                <div className='detailed-desc'>
                                    Whether you want to upgrade a single room or renovate your entire house, we can do it for you                            
                                    just the way you want it. Simply send us a query or quote request to find out more on what we can do for
                                    you.
                                </div>
                                <div className='detailed-desc'>
                                    A good interior can have a positive impact emotionally. It boosts the 
                                    impression of you among visitors. Upgrading also helps to improve
                                    the functional spaces in the house. 
                                </div>
                                <div className='detailed-desc'>
                                    At Karthek & Co Contracting we pride ourselves in considering all aspects of
                                    the client's requirements including emotional aspects and incorporating into
                                    your space, turning it into your unique space.
                                </div>
                                <div className='sub title-desc'>            
                                    Do you do exterior renovation?
                                </div>
                                <div className='detailed-desc'>
                                    Of course, we do all sorts all sorts of renovation. We do landscaping, backyard renovation, front-drive extensions.
                                </div>
                            </div>
                        </section>
                    </section>
                </section>
            </section>
        </main>
    );
};

export default Renovation;