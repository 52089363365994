import React from "react";
// import { useState } from "react";
// import { useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
// import { findDOMNode } from "react-dom";
import '../../styles/dropdown-menu.scss';
import { IoClose, IoMenu } from "react-icons/io5";
import { FaCaretDown } from "react-icons/fa6";
import { closeMenu, hideServicesMenu } from '../../utilities/utility.js';
// import DropdownMenu from "../DropDownComponent/DropDownComponent";

const NavigationComponent = () => {

    // const [isDropdownVisible, setDropdownVisible] = useState(false);

    // const showServicesMenu = () => {
    //     setDropdownVisible(true);
    // };

    // const hideServicesMenu = () => {
    //     setDropdownVisible(false);
    // };

    // const navigate = useNavigate();

    // const goToContactUs = () => {
    //     navigate("/contact");
    // };

    // const goToHome = () => {
    //     navigate("/");
    // };

    function openMenu() {
        // const menu = findDOMNode(this);
        const menu = document.getElementById('nav-menu');
        // console.log('menu ', menu);
        const navigationMenu = document.getElementById('nav-toggle');
        // console.log('navigationMenu', navigationMenu);
        menu.classList.add("show-menu");
        navigationMenu.classList.add('open-menu');
        // if(menu.classList.contains('show-menu')) {
        //     console.log('contains');
        //     if(!navigationMenu.classList.contains('open-menu')) {
        //         navigationMenu.classList.toggle('open-menu');
        //     }
        // } else {
        //     if(navigationMenu.classList.contains('open-menu')) {
        //         navigationMenu.classList.toggle('open-menu');
        //     }
        // }
    };

    
    const toggleServicesMenu = () => {
        const services_menu = document.getElementById('services_list');
        if(services_menu.classList.contains('open')) {
            // services_menu.classList.remove('open');
            // setDropdownVisible(false);
            hideServicesMenu();
        } else {
            openServicesMenu();
            // services_menu.classList.add('open');
            // setDropdownVisible(true);
        }
    };

    const getMask = () => {

        // return (<div class='mask'></div>)
        let mask = document.createElement('div');
        mask.setAttribute('class', 'mask');
        mask.setAttribute('id', 'mask-container');

        mask.addEventListener("click", function(e) {
            console.log('clicked');
            hideServicesMenu();
        });

        return mask;
    };

    const openServicesMenu = () => {
        const services_menu = document.getElementById('services_list');
        services_menu.classList.add('open');
        
        const appRoot = document.getElementById('app');
        appRoot.append(getMask());
        // setDropdownVisible(true);
    };

    // const scrollToSection = (elementRef) => {
    //     console.log('scrollToSection', elementRef)
    //     closeMenu();

    //     const element = document.getElementById(elementRef);        
    //     window.scrollTo({
    //         top: element.current.offsetTop,
    //         behavior: 'smooth'
    //     })
    // }

    const images = require.context('../../../images', true);
    let logo = images('./logo2.png');

    return (
        <header className="navigation">
            <nav className='nav container'>
                <div className='logo'>
                    <NavLink to="/">
                        <img src={logo} alt='Home' className='static-visual'></img>
                        {/* <svg>
                            <text x="50%" y="50%" dy=".35em" textAnchor="middle">Karthek & Co.</text> */}
                            {/* <div className='head'>Karthek</div>
                            <div className='sub'>& Co.</div> */}
                        {/* </svg> */}
                    </NavLink>
                </div>
                <div className="nav__toggle" onClick={openMenu} id="nav-toggle">
                    <IoMenu />
                </div>
                <div className="nav_menu" id="nav-menu">
                    <div className="nav__list">
                        <div className="nav__item">
                            <NavLink to="/" onClick={closeMenu} className="nav__link">
                                Home
                            </NavLink>
                        </div>
                        <div className="nav__item">
                            <NavLink to="/portfolio" onClick={closeMenu} className="nav__link">
                                Portfolio
                            </NavLink>
                        </div>
                        <div className="nav__item sub_menu_parent">
                            <NavLink onClick={toggleServicesMenu} className="nav__link">
                            {/* <NavLink onMouseEnter={openServicesMenu} onMouseLeave={hideServicesMenu} className="nav__link"> */}
                                Services <FaCaretDown className="menu_icon"/>
                            </NavLink>
                            <div className="nav__sub__item" id="services_list">
                                <div className="nav__item">
                                    {/* <NavLink to="/" onClick={() => scrollToSection('custom-build')} className="nav__link"> */}
                                    {/* <NavLink className="nav__link"> */}
                                    <NavLink to="/services/custom-homes" onClick={closeMenu} className="nav__link">
                                        New Construction
                                    </NavLink>
                                </div>
                                <div className="nav__item">
                                    <NavLink to="/services/renovation" onClick={closeMenu} className="nav__link">
                                        Renovation
                                    </NavLink>
                                </div>
                                <div className="nav__item">
                                    <NavLink to="/services/landscaping" onClick={closeMenu} className="nav__link">
                                        Landscaping
                                    </NavLink>
                                </div>
                                {/* <div className="nav__item">
                                    <NavLink to="/services/renovation" onClick={closeMenu} className="nav__link">
                                        Kitchen Renovation
                                    </NavLink>
                                </div>
                                <div className="nav__item">
                                    <NavLink to="/services/renovation" onClick={closeMenu} className="nav__link">
                                        Bathroom Renovation
                                    </NavLink>
                                </div>
                                <div className="nav__item">
                                    <NavLink to="/services/painting" onClick={closeMenu} className="nav__link">
                                        Painting
                                    </NavLink>
                                </div>                               */}
                            </div>
                            
                        </div>
                        <div className="nav__item">
                            <NavLink to="/contact" onClick={closeMenu} className="nav__link">
                                Contact Us
                            </NavLink>
                        </div>
                        <div className="nav__item">
                            <NavLink to="/contact" onClick={closeMenu} className="nav__link">
                                Request a Quote
                            </NavLink>
                        </div>
                    </div>
                    <div className="nav__close" onClick={closeMenu} id="nav-close">
                        <IoClose />
                    </div>
                </div>                
            </nav>
            {/* <div className='logo' onClick={goToHome}><div className='head'>Karthek</div><div className='sub'>& Co.</div></div>
            <div className='navigation-menu mx-auto flex px-10 py-20 md:flex-row flex-col items-center'>
                <div className='about'><div className='head_label'>About Us</div></div>
                <div className='services' onMouseEnter={showServicesMenu} onMouseLeave={hideServicesMenu}>
                    <div className='head_label'>Services</div>
                    {isDropdownVisible && <DropdownMenu />}
                </div>
                <div className='contact' onClick={goToContactUs}><div className='head_label'>Contact Us</div></div>
                <div className='quotation-request'><div className='head_label'>Request a Quote</div></div>
            </div> */}
        </header>
    );
};

export default NavigationComponent;