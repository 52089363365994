import React, {useState} from 'react';
import Carousel from 'react-bootstrap/Carousel';

const data = [
    {
     image: require('../../../images/portfolio/1.jpg'), 
     caption:"",
     description:"Redesign Indoor Space",
     key: "Image1",
     alt: "Image 1"
    },
    {
      image:require('../../../images/portfolio/10.jpg'), 
      caption:"",
      description:"Redesign Closet Spaces",
      key: "Image2",
      alt: "Image 2"
     }, 
     {
      image:require('../../../images/portfolio/4.jpg'), 
      caption:"",
      description:"Upgrade Flooring",
      key: "Image4",
      alt: "Image 4"
     }, 
     {
      image:require('../../../images/portfolio/landscape/12.jpg'), 
      caption:"",
      description:"Rediscover Outdoor Space",
      key: "Image5",
      alt: "Image 5"
     }, 
     {
      image:require('../../../images/portfolio/landscape/7.jpg'), 
      caption:"",
      description:"Update Lawn / Driveway",
      key: "Image6",
      alt: "Image 6"
     }, 
     {
      image:require('../../../images/portfolio/landscape/15.jpg'), 
      caption:"",
      description:"Fencing",
      key: "Image7",
      alt: "Image 7"
     } 
  ]
  
  const HomeCarousel = () => {
    const [index, setIndex] = useState(0);
    const handleSelect = (selectedIndex, e) => {
      setIndex(selectedIndex);
    };
  
    return (
      <Carousel activeIndex={index} onSelect={handleSelect}>
         {data.map((slide, i) => {
          return (
            <Carousel.Item
            key={slide.key}>        
          <img
            className="d-block w-100"
            src={slide.image}
            alt={slide.alt}            
          />
          <Carousel.Caption>
            <h3>{slide.caption}</h3>
            <p>{slide.description}</p>
          </Carousel.Caption>
        </Carousel.Item>
          )
        })}
        
      </Carousel>
    );
  };

  export default HomeCarousel;
  