import React from 'react';
// import { useRef } from 'react';
import CarouselComponent from '../CarouselComponent/CarouselComponent.js';
import '../../styles/landing.scss';

const Landing = () => {

    // const custom_build = useRef(null);
    const images = require.context('../../../images', true);
    let logo = images('./logo.png');

    return (
        <section className='landing'>
            <section className='portfolio'>
                <section className='visuals'>
                    <CarouselComponent/>
                </section>
                <section className='welcome-desc'>
                    <div className='big-logo'>
                        <img src={logo} alt='Home' className='static-visual'></img>
                    </div>
                    <div className='landing-dec'>
                        <div className='title-desc'>            
                            Welcome to <span className='highlight'>Karthek & Co Contracting</span>
                        </div>
                        <div className='detailed-desc'>
                            Bringing your dream project to reality with us!
                            Whether you want to build your dream home, upgrade your commercial property or renovate the interiors, we here at Karthek & Co. 
                            guarantee you to provide the best service that is worth your money. Our team has well experienced
                            and qualified professionals that make sure that the job is finished to highest standards.
                        </div>
                        <div className='detailed-desc'>
                            There is no such thing as small work. So do not hesitate to contact us. No matter what you're query 
                            is we will be happy to assist you.
                        </div>
                    </div>
                </section>            
            </section>
        </section>
    );
};

export default Landing;