import React from 'react';
import './styles/landing.scss';

const Landscaping = () => {

    const images = require.context('../images/portfolio/landscape', true);
    let image = images('./' + 5 + '.jpg');

    return (
        <main>
            <section className='body'>
                <section className='landing'>
                    <section className='project-details' id='interior'>
                        <section className='visuals'>
                            <img src={image} alt='Image 1' className='static-visual'></img>
                        </section>
                        <section className='welcome-desc'>
                            <div className='landing-dec'>
                                <div className='title-desc'>            
                                    Upgrade your outdoor space to get an inspiration lift
                                </div>
                                <div className='detailed-desc'>
                                Why upgrade your outdoor space? A functional stylish outdoor space makes
                                spending time outdoors enjoyable and more useful. It improves the image of the
                                house overall thereby increasing the value of the property financially.
                                </div>
                                <div className='sub title-desc'>            
                                    What kind of services do you provide?
                                </div>
                                <div className='detailed-desc'>
                                    We do a-z in landscaping. Some of them include interlocking, fencing, slab steps, garden bed, sod.
                                    Check out our portfolio page to see our work.
                                </div>
                            </div>
                        </section>
                    </section>
                </section>
            </section>
        </main>        
    );
};

export default Landscaping;