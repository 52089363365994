import React from 'react';
// import { useRef } from 'react';
import './styles/landing.scss';

const CustomHomes = () => {

    // const custom_build = useRef(null);
    const images = require.context('../images/portfolio/landscape', true);
    let image = images('./' + 7 + '.jpg');
    return (
        <main>
            <section className='body'>
                <section className='landing'>
                    <section className='project-details' id='custom-homes'>
                        <section className='visuals'>
                            <img src={image} alt='Image 11' className='static-visual'></img>
                        </section>
                        <section className='welcome-desc'>
                            <div className='landing-dec'>
                                <div className='title-desc'>            
                                    Build your custom home just as you like it
                                </div>
                                <div className='detailed-desc'>
                                    Have a dream home in mind? We can help you make it a reality. Building a
                                    custom home can be stressful and expensive if not done by the right people. 
                                    Therefore, take your time to compare and hire the best professionals. We at 
                                    Karthek & Co Contracting are more than happy to give you a realistic picture 
                                    on what can be achieved within a given budget. 
                                </div>
                            </div>
                        </section>
                    </section>
                </section>
            </section>
        </main>
    );
};

export default CustomHomes;