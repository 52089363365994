import React from 'react';

import '../../styles/contact.scss';
import { useEffect } from "react";
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { AnimatePresence, motion } from 'framer-motion';
import { FaCheck, FaCircleExclamation } from 'react-icons/fa6';
import emailjs from "@emailjs/browser";

export const Input = ({label, type, id, placeholder, elementType}) => {

    const { register, formState: { errors } } = useFormContext();

    const findInputError = (errors, name) => {
        const filtered = Object.keys(errors)
        .filter(key => key.includes(name))
        .reduce((cur, key) => {
            return Object.assign(cur, { error: errors[key] })
        }, {})

        return filtered
    }

    const isFormInvalid = err => {
        if (Object.keys(err).length > 0) {
            return true
        } else {
            return false
        }
    }

    const inputError = findInputError(errors, label)
    const isInvalid = isFormInvalid(inputError)

    if(elementType === 'inputText') {
        return (
            <div className='field'>
                <label htmlFor={id} className='field-label'>{label}</label>
                <textarea id={id} className='field-input' name='message' rows='4'
                {...register(label, {required: {value: false}})}/>
            </div>
        );
    } else if (type === 'number') { 
    // console.log('number input');
        return (
            <div className='field'>
                <label htmlFor={id} className='field-label'>{label}</label>
                <AnimatePresence mode="wait" initial={false}>
                {isInvalid && (
                    <InputError
                    message={inputError.error.message}
                    key={inputError.error.message}
                    />
                )}
                </AnimatePresence>
                <input id={id} type='text' inputMode='numeric' className='field-input' 
                {...register(label, {required: {value: true, message: 'required'}})}/>
            </div>
        );

    } else {

        return (
            <div className='field'>
                <label htmlFor={id} className='field-label'>{label}</label>
                <AnimatePresence mode="wait" initial={false}>
                {isInvalid && (
                    <InputError
                    message={inputError.error.message}
                    key={inputError.error.message}
                    />
                )}
                </AnimatePresence>
                <input id={id} type={type} className='field-input' 
                {...register(label, {required: {value: true, message: 'required'}})}/>
            </div>
        );
    }
}

const InputError = ({ message }) => {
    return (
      <motion.p
        className="error-msg"
        {...framer_error}
      >
        <div className='icon'><FaCircleExclamation /></div>
        {message}
      </motion.p>
    )
  }
  
  const framer_error = {
    initial: { opacity: 0, y: 10 },
    animate: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: 10 },
    transition: { duration: 0.2 },
  }


const ContactComponent = () => {

    useEffect(() => emailjs.init("MFI94maIE38dZ_5cN"), []);
    const methods = useForm();

    // const [loading, setLoading] = useState(false);

    const submitQuery = methods.handleSubmit(data => {
        // console.log('data', data);

        const serviceId = "service_rxp1j3h";
        const templateId = "template_ykr7zk9";

        try {
            // setLoading(true);
            emailjs.send(serviceId, templateId, {
                to_name: 'Karthekandco',
                from_name: data['Your Name'],
                message: data['Message'],
                phone: data['Phone Number'],
                email: data['Email Address'],
                city: data['City']
                // province: data.Province
            });

        } catch (error) {
            console.log(error);
        } finally {
            // setLoading(false);
            showSuccessMessage();
        }
    });

    const showSuccessMessage = () => {
        try {
            const status = document.getElementById('status');
            if(status) {
                status.classList.add('show-msg');
            }
        } catch (error) {
            console.log(error);
        } finally {
            clearForm();
        }
    };

    const clearForm = () => {
        try {
            const queryForm = document.getElementById('query-form');
            if(queryForm) {
                queryForm.reset();
            }
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <section className='contact-us'>
            <section className='contact-form'>
                <FormProvider {...methods}>
                    <form onSubmit={e => e.preventDefault()} noValidate className='form-container' id='query-form'>
                        <div className='container'>
                            <Input label='Your Name' type='text' id='name' placeholder='Name' elementType='input'/>
                            <Input label='Phone Number' type='number' id='phone' placeholder='Phone' elementType='input'/>
                            <Input label='Email Address' type='text' id='email' placeholder='Email Address' elementType='input'/>
                            <Input label='City' type='text' id='city' placeholder='City' elementType='input'/>
                            {/* <Input label='Province' type='text' id='province' placeholder='Province' elementType='input' /> */}
                            <Input label='Message' id='message' placeholder='Your Message' elementType='inputText' />
                            <button onClick={submitQuery} className='contact-submit field'>Submit <span class='sub-text'>Form</span></button>
                        </div>
                    </form>
                </FormProvider>
            </section>
            <section className='status-msg' id='status'><div className='icon'><FaCheck/></div>Your message is successfully sent. We will get back to you soon</section>
        </section>
    );
};

export default ContactComponent;