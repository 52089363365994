import './styles/header.scss';
import './styles/navigation.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import ContactComponent from './components/ContactComponent/ContactComponent.js';

function Contact() {
  return (
    <main>
      <section className='body contact'>
      <div className='title-desc'>            
          Send us a message
      </div>
      <ContactComponent/>
      </section>
    </main>
  );
}

export default Contact;