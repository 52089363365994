
export const closeMenu = () => {
    const menu = document.getElementById('nav-menu');
    const navigationMenu = document.getElementById('nav-toggle');

    if(menu.classList.contains("show-menu")) {
        menu.classList.remove("show-menu");
    }
    if(navigationMenu.classList.contains('open-menu')) {
        navigationMenu.classList.remove('open-menu');
    }

    hideServicesMenu();
};

export const hideServicesMenu = () => {
    const services_menu = document.getElementById('services_list');
    if(services_menu.classList.contains('open')) {
        services_menu.classList.remove('open');
    }

    const mask = document.getElementById('mask-container');
    if(mask) {
        mask.remove();
    }
    // setDropdownVisible(false);
};


export const appendImages = (images, isPair) => {

    let count = images.length;

    let images_list = [];
    
    for(let j=0; j<count; j++) {
        let image_path = images[j];
        // console.log('image', image_path);
        let classes_list = 'block';

        if (isPair) {
            if(j%2 === 0) {
                classes_list += ' left';
            } else {
                classes_list += ' right';
            }
        }

        let alt_image = 'Image ' + j
        images_list.push(
        <div className={classes_list} key={alt_image}>
            <img src={image_path} alt={alt_image} className='portfolio-visual'></img>
        </div>
        );
    }
    return images_list;
};
