import './styles/header.scss';
import './styles/navigation.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import Landing from './components/Landing/Landing.js';

function Home() {
  return (
    <main>
      <section className='body'>
      <Landing/>
      </section>
    </main>
  );
}


export default Home;