import React from "react";
import { NavLink } from "react-router-dom";
import '../../styles/footer.scss';
import { FaPhone, FaEnvelope, FaInstagram } from "react-icons/fa6";
import { closeMenu } from "../../utilities/utility";

const Footer = () => {

    return (
        <section className="footer">
            <div className="info sec">
                Karthek & Co Contracting is an Ontario based company specializing in all kinds of construction and renovation works
                <div className="services-info">Service offered at best prices. If you are looking for reliable contractors in canada, contact us. We do any services related to 
                lawn, landscaping, sprinkler, garden, sod installation, slab steps, garden bed, patio, yard, swimming pool, step construction, interlocking, fencing
                for both home and commercial properties. We also build custom homes or any work related to it such as roofing, siding, painting, repair and redo floors, cabinet
                closest, bath tub and shower units.
                </div>
            </div>
            <div className="contact sec">
                <div className="head_label">CONTACT US</div>
                <div className="phone contact_item">
                    <a className="telephone contact_item_link" href="tel:6473278325"><div className="icon"><FaPhone/></div>647-327-8325</a>
                </div>
                <div className="email contact_item">
                    <a className="email-link contact_item_link" href="mailto:karthek419on@gmail.com"><div className="icon"><FaEnvelope/></div>karthek419on@gmail.com</a>
                </div>                
            </div>
            <div className="follow sec">
                <div className="head_label">FOLLOW US ON</div>
                <div className="social contact_item">
                    <a className="social_media_link contact_item_link" href="https://www.instagram.com/karthek_and_co_contracting" target="_blank"><div className="icon"><FaInstagram/></div></a>
                </div>
                <div className="social contact_item privacy">
                    <NavLink to="/privacy" onClick={closeMenu} className="nav__link">
                        Privacy Policy
                    </NavLink>
                    {/* <a className="social_media_link contact_item_link" href="/contact">Privacy Policy</a> */}
                </div>
            </div>
        </section>
    );
};

export default Footer;